import React from 'react';


const Faq = () => (
	<div>
		<h2 className="text-3xl leading-9 font-extrabold text-gray-900 text-center">
			Frequently asked questions
		</h2>
		<div className="mt-12">
			<dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:col-gap-8 md:row-gap-12 lg:grid-cols-3">
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						Where are the VMs hosted?
					</dt>
					<dd className="text-base leading-6 text-gray-500">
						Currently only Digitalocean is supported but we're working on
						adding more partners as soon as possible. Please subscribe to
						get notified once we add new providers.
					</dd>
				</div>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						Where can I find the server after install?
					</dt>
					<dd className="text-base leading-6 text-gray-500">
						Your server will be created and installed on your account that you
						used to connect with. Your client config is sent to your email after
						a successful install.
					</dd>
				</div>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						Why do I do if my server breaks?
					</dt>
					<dd className="text-base leading-6 text-gray-500">
						You should attempt to restore it by following our guides from the
						knowledgebase. If you fail to repair it then you can close the
						server and order another install from us.
					</dd>
				</div>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						How many devices can I connect?
					</dt>
					<dd className="text-base leading-6 text-gray-500">
						As many as your server supports. We place absolutely no limits
						and you can always upgrade your server to accommodate your needs.
					</dd>
				</div>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						Why another VPN service?
					</dt>
					<dd className="text-base leading-6 text-gray-500">
						We're regular VPN users as well. We don't trust anything
						else but full transparency and we have good reasons for it. You
						should do the same: data is the new oil after all.
					</dd>
				</div>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						Do you have any VPN apps?
					</dt>
					<dd className="text-base leading-6 text-gray-500">
						No! That will defeat our entire purpose but we do provide a
						comprehensive list of supported apps (the official ones). You're
						free to experiment with other apps though.
					</dd>
				</div>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						Do you offer support for the install?
					</dt>
					<dd className="text-base leading-6 text-gray-500">
						We offer support to cover the initial install and configuration
						of your new server. If your instance stops working we have
						no way of repairing it without compromising our promise
						to respect your privacy 100%
					</dd>
				</div>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						What technologies do you use?
					</dt>
					<dd className="text-base leading-6 text-gray-500">
						What we install on your server are open source packages
						that can be verified and audited. Most notable ones are
						docker, OpenVPN and Wireguard servers embedded in docker.
						Please see our knowledgebase for a complete list.
					</dd>
				</div>
				<div className="space-y-2">
					<dt className="text-lg leading-6 font-medium text-gray-900">
						Do you offer refunds?
					</dt>
					<dd className="text-base leading-6 text-gray-500">
						Yes! No questions asked refund if the instance we installed
						does not work due to our fault or issues with the VM
						providers. We can refund or retry with a new config. In any
						case, please contact us for any refund requests.
					</dd>
				</div>
			</dl>
		</div>
	</div>
);

export default Faq;
